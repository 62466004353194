.wrapper {
  background: #FAFAFA;
  border-radius: 5px;
  height: 50px;
  width: 100%;
  border: 1px solid #E9EEF5;
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex: 0 0 20%;
  cursor: pointer;
}

.selected {
  border-radius: 4px;
  background: #E9EEF5;
}
