.close-icon {
  position: absolute;
  right: 40px;
  top: 40px;
  cursor: pointer;
}

.header {
  margin-bottom: 20px;
}

.info {
  display: flex;
  flex-direction: row;
}

.dest, .hotel {
  font-size: 14px;
  margin-bottom: 20px;
}

.thumb {
  height: 174px;
  width: 236px;
  margin-right: 28px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    object-fit: cover;
  }
}

.button {
  width: 132px;
  height: 50px;

  background: #57BCDD;
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.25);
  border-radius: 5px;
  border: none;

  font-weight: 800;
  font-size: 14px;
  line-height: 150%;
  color: #FAFAFA;
}

.filters {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
}

.rooms {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 74px;

  div {
    margin-left: 16px;
  }
}

.input {
  width: 251px;
  height: 50px;

  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 5px;
}

.row {
  display: flex;

  &:first-child {
    margin-top: 10px;
  }

  div:first-child {
    margin-right: 16px;
  }
}

.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.net {
  font-weight: bold;
  font-size: 20px;
}

span {
  font-size: 12px;
}
