.wrapper {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
}

.button {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #5EBB94;
  position: relative;
  margin-right: 6px;
  &:last-of-type {
    margin-right: 0;
  }
  &--filled {
    &:after {
      content: '';
      position: absolute;
      top: 2px;
      left: 2px;
      right: 2px;
      bottom: 2px;
      border-radius: 50%;
      background-color: #5EBB94;
    }
  }
}