.header {
  position: relative;
  display: flex;
  height: 100vh;
  flex-direction: column;
  background-image: url('./img/bg_main.jpg');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

form {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 15px;

  input, button {
    margin-bottom: 10px;
  }

  button {
    margin-top: 10px;
  }
}

figcaption {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 150%;
  color: #9E9E9E;
}

h1 {
  font-size: 24px;
}

h4 {
  font-weight: normal;
  font-size: 14px;
}
