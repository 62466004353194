.content {
  position: absolute;
  top: 50px;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 30px 50px 0 150px;
  height: 560px;
  margin: auto;
}

.picker {
  display: inline-block;
  width: 340px;
}

.content-header {
  position: absolute;
  top: 40px;
  left: 0;
  bottom: 0;
  right: 0;
}

.content-body {
  overflow: auto;
  padding-bottom: 40px;
  background: #FAFAFA;
  border: none;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  height: 434px;
}

.buttons {
  display: flex;
  margin-top: 140px;

  button {
    width: 150px;
    height: 50px;

    background: #57BCDD;
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.25);
    border-radius: 5px;
    border: none;

    font-weight: 800;
    font-size: 14px;
    line-height: 150%;
    color: #FAFAFA;

  }

  button:last-child {
    background: #FAFAFA;
    color: #57BCDD;
    border: 1px solid #57BCDD;
    margin-left: 10px;
  }
}
