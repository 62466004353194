.wrapper {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
}

.inner {
  display: flex;
  flex-flow: row;
  align-items: center;
  list-style: none;
  height: 100%;
}

.item {
  margin: 0 10px;
  font-size: 14px;
  outline: none;
  &--active {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background: #57BCDD;
  }
  :focus {
    outline: none;
  }
}

.link {
  text-decoration: none;
  color: #fafafa;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  box-sizing: border-box;
  cursor: pointer;
}

.prev,
.next,
.break {
  color: #fafafa;
  &--link {
    outline: none;
    &:focus {
      outline: none;
    }
  }
}

.first {
  color: #fafafa;
  margin-right: 10px;
  cursor: pointer;
}

.last {
  color: #fafafa;
  margin-left: 10px;
  cursor: pointer;
}
