.additional__menu {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: row;
  &--vertical {
    flex-direction: column;
    left: 0;
    top: 50vh;
    transform: translate(0, -50%);
    align-items: flex-start;
  }
}
.additional__menu__hotels {
  background-color: #57bcdd;
}
.additional__menu__tours {
  background-color: #f58686;
}
.additional__menu__flights {
  background-color: #af8de7;
}
.additional__menu__cruises {
  background-color: #5ebb94;
}
.additional__menu__carhire {
  background-color: #f0c043;
}

.additional__menu__item {
  display: flex;
  flex-direction: column;
  margin: 8px 8px 0 8px;
  padding: 0;
  height: 120px;
  width: 80px;
  border-radius: 100px 100px 0 0;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  outline: none;
  &--vertical {
    flex-direction: row-reverse;
    height: 80px;
    width: 108px;
    border-radius: 0 100px 100px 0;
    margin-left: 0;
    justify-content: flex-start;
    &:hover {
      display: flex;
      width: auto;
      justify-content: flex-end;
      .menu__text {
        display: flex;
        padding: 0 10px;
        align-items: center;
      }
    }
    .menu__text {
      display: none;
    }
    .menu__icon {
      margin: 10px 10px 10px 0;
    }
  }
  &--horizontal {
    &:hover {
      height: 140px;
      width: 80px;
    }
  }
}

.active {
  display: flex;
  width: auto;
  justify-content: flex-end;

  .menu__text {
    display: flex;
    padding: 0 10px;
    align-items: center;
  }
}

.menu__text {
  text-transform: uppercase;
  color: #ffffff;
  font-size: 12px;
  text-align: center;
}

.menu__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 60px;
  background-color: #ffffff;
  border-radius: 100px;
  margin: 10px;
}
.menu__img {
  height: 36px;
  width: 36px;
}
