.contacts {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 246px;

  .block {
    min-height: 256px;
    &:first-child {
      margin-right: 76px;
    }
  }

  .content {
    margin-right: 60px;
  }

  h1 {
    font-size: 24px;
    color: #FAFAFA;
    margin-bottom: 30px;
  }

  h3 {
    font-size: 12px;
    color: #9E9E9E;
    margin-bottom: 14px;
  }

  p {
    position: relative;
    line-height: 26px;
    display: flex;
    margin-bottom: 8px;
    font-family: SF Pro Display, sans-serif;
    font-size: 14px;
    color: #FAFAFA;
    margin-left: 26px;


    img {
      width: 16px;
      height: 16px;
      position: absolute;
      left: -26px;
      bottom: 4px;
    }
  }

  .info-block {
    display: flex;
    flex-direction: row;
  }
}
