.wrapper {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 38px 50px 80px 162px;
  width: 100%;
  box-sizing: border-box;
}

.top-hotels {
  margin-top: 10px;
}

.form {
  width: 100%;
}
