.delimiter {
  width: 1px;
  height: 14px;
  opacity: 0.5;
  border-radius: 1px;
}
.white {
  background-color: #fafafa;
}
.dark {
  background-color: #000000;
}
