.wrapper {
  width: 30%;
  margin-right: 10px;
  background: #fafafa;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 19px 28px;
}

.header {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  margin-bottom: 20px;
  &__title {
    font-weight: bold;
    font-size: 18px;
    color: #1F1B1B;
  }
  &__btn {
    font-weight: bold;
    font-size: 14px;
    color: #57BCDD;
    background: transparent;
    outline: 0;
    border: none;
    cursor: pointer;
  }
}

.group {
  margin-bottom: 20px;
  &:last-of-type {
    margin-bottom: 0;
  }
  &__title {
    font-size: 14px;
    color: #9E9E9E;
    margin-bottom: 8px;
  }
  &__item {
    margin-bottom: 10px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
