.message {
  padding: 10px 0;
}

.success {
  color: green;
}

.failed {
  color: red;
}
