.select {
  outline: none;
  border: none;
  box-shadow: none;
}

.select:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.react-select__group {
  &:first-of-type,
  &:last-of-type {
    .react-select__option:before {
      content: "";
      position: absolute;
      left: 4px;
      top: 10px;
      background-size: 16px 16px;
      height: 16px;
      width: 16px;
    }
  }

  &:first-of-type {
    .react-select__option.react-select__option--is-selected {
      &:before {
        background-image: url("./img/destination_icon.svg");
      }
    }
    .react-select__option:before {
      background-image: url("img/destination_icon_dark.svg");
    }
  }

  &:last-of-type {
    padding-left: 20px;

    .react-select__option.react-select__option--is-selected {
      &:before {
        background-image: url("./img/rooms_icon.svg");
      }
    }
    .react-select__option:before {
      background-image: url("img/rooms_icon_dark.svg");
    }
  }
}

.react-select__menu:after {
  content: "";
  position: absolute;
  width: 1px;
  height: 90%;
  background: #9E9E9E;
  opacity: 0.5;
  border-radius: 1px;
  left: 50%;
  top: 6%;
  display: block;
}

.react-select__input {
  input {
    opacity: 1 !important;
  }
}

.travel-art-select__control {
  border-radius: 5px;
  background-color: rgba(250, 250, 250, 0.3);
  color: rgba(250, 250, 250, 0.5);
}
