.wrapper {
  height: 174px;
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #fff;
  padding-bottom: 20px;
  padding-top: 20px;
  border-bottom: 1px solid #e9eef5;
  border-radius: 1px;

  &:first-of-type {
    padding-top: 0;
  }

  &:last-of-type {
    border: none;
    padding-bottom: 0;
  }
}

.thumb {
  height: 100%;
  width: 202px;
  margin-right: 28px;
  img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    object-fit: cover;
  }
}

.general {
  flex: 1 1 40%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-right: 12px;
  height: 100%;
}

.header {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 8px;
  .title {
    margin-right: 20px;
  }
}

.area {
  margin-bottom: 10px;
  color: #9e9e9e;
  font-size: 12px;
}

.city {
  margin-bottom: 10px;
  font-size: 12px;
  color: #1f1b1b;
}

.footer {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.review {
  margin-bottom: 50px;
}

.price {
  text-align: right;

  &__title {
    font-weight: 400;
    font-size: 12px;
    color: #1F1B1B;
    margin-right: 10px;
  }
  &__value {
    font-weight: bold;
    font-size: 20px;
    color: #1F1B1B;
  }
}
.options {
  margin-top: 8px;
  img {
    margin-right: 20px;
    &:last-of-type {
      margin-right: 0;
    }
  }
}

.tabs {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 25px;
  margin-left: -10px;
  &__tab {
    cursor: pointer;
    font-size: 14px;
    color: #1F1B1B;
    padding: 10px 12px;
    &:last-of-type {
      margin: 0;
    }
    &--active {
      background-color: #e9eef5;
      border-radius: 5px;
    }
  }
}

.room {
  &__group {
    margin-top: 10px;
    &__title {
      color: #1f1b1b;
      font-weight: 700;
      font-size: 14px;
    }
  }

  &__row {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    border-bottom: 1px solid #e9eef5;
    border-radius: 1px;
    &__title {
      font-size: 14px;
      font-weight: bold;
      color: #9e9e9e;
      min-width: 160px;
    }

    &__middle {
      display: flex;
      flex-flow: row;
      justify-content: center;
      align-items: center;

      p {
        position: relative;
        font-size: 14px;
        color: #1F1B1B;

        img {
          position: absolute;
          left: -26px;
          bottom: 2px;
        }
      }
    }

    &__secondary {
      display: flex;
      flex-flow: row;
      justify-content: flex-start;
      align-items: center;
      &__price {
        font-size: 12px;
        font-weight: bold;
        color: #000;
        margin: 0 20px;
      }
      &__reserve {
        padding: 10px 20px;
        color: #57BCDD;
        font-size: 14px;
        background: transparent;
        border: none;
        outline: none;
        cursor: pointer;
        &:hover {
          background-color: #57BCDD;
          border-radius: 5px;
          color: #fff;
        }
      }
    }
  }
}
