.header {
  position: relative;
  display: flex;
  height: 100vh;
  flex-direction: column;
  background-image: url('./img/bg_main.jpg');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.logo {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 232px;
  height: 65px;
}

/*.additional_menu {*/
/*  position: absolute;*/
/*  left: 50%;*/
/*  transform: translateX(-50%);*/
/*  bottom: 0;*/
/*  display: flex;*/
/*  align-items: flex-end;*/
/*  justify-content: center;*/
/*  flex-direction: row;*/
/*}*/
/*.additional_menu_hotels {*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  margin: 8px 8px 0 8px;*/
/*  padding: 0;*/
/*  width: 80px;*/
/*  background-color: #57bcdd;*/
/*  border-radius: 100px 100px 0 0;*/
/*  height: 120px;*/
/*  transition: max-height 0.15s ease-out;*/
/*}*/
/*.menu_icon {*/
/*  display: flex;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  height: 60px;*/
/*  width: 60px;*/
/*  background-color: #ffffff;*/
/*  border-radius: 100px;*/
/*  margin: 10px;*/
/*}*/
/*.menu_img {*/
/*  height: 36px;*/
/*  width: 36px;*/
/*}*/
/*.additional_menu_tours {*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  margin: 8px 8px 0 8px;*/
/*  padding: 0;*/
/*  height: 120px;*/
/*  width: 80px;*/
/*  background-color: #f58686;*/
/*  border-radius: 100px 100px 0 0;*/
/*}*/
/*.additional_menu_flights {*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  margin: 8px 8px 0 8px;*/
/*  padding: 0;*/
/*  height: 120px;*/
/*  width: 80px;*/
/*  background-color: #af8de7;*/
/*  border-radius: 100px 100px 0 0;*/
/*}*/
/*.additional_menu_cruises {*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  margin: 8px 8px 0 8px;*/
/*  padding: 0;*/
/*  height: 120px;*/
/*  width: 80px;*/
/*  background-color: #5ebb94;*/
/*  border-radius: 100px 100px 0 0;*/
/*}*/
/*.additional_menu_carhire {*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  margin: 8px 8px 0 8px;*/
/*  padding: 0;*/
/*  height: 120px;*/
/*  width: 80px;*/
/*  background-color: #f0c043;*/
/*  border-radius: 100px 100px 0 0;*/
/*}*/
/*.menu_text {*/
/*  text-transform: uppercase;*/
/*  color: #ffffff;*/
/*  font-size: 12px;*/
/*  text-align: center;*/
/*}*/

/*.additional_menu_item {*/
/*  transition: height 0.2s ease-in-out;*/
/*  text-decoration: none;*/
/*}*/

/*.additional_menu_item:hover {*/
/*  height: 140px;*/
/*  width: 80px;*/
/*}*/
