.wrapper-checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
}

[type="checkbox"] {
  position: absolute;
  display: flex;
  flex-direction: row;
  opacity: 0;


  & + label {
    position: relative;
    display: flex;
    cursor: pointer;
    padding: 0;
    font-size: 14px;
  }

  & + label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: #E9EEF5;
    border-radius: 5px;
    box-sizing: border-box;
  }

  &:checked + label:before {
    background: #57BCDD;
  }

  &:disabled + label {
    color: #969696;
    cursor: not-allowed;
  }

  &:checked + label:after {
    content: '';
    position: absolute;
    background: transparent;
    left: 7px;
    top: 4px;
    width: 5px;
    height: 8px;
    border: solid #ffffff;
    border-width: 0 1px 1px 0;
    transform: rotate(45deg);
  }
}

