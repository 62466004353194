.DateRangePicker,
.DateRangePicker > div {
  height: 50px;
}

.DateRangePickerInput {
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  background-color: #938884;
  border: none;
  border-radius: 5px;
  padding: 17px 20px;
}

.DateRangePickerInput_calendarIcon {
  padding: 15px 0 15px;
  margin: 0;
}

.DateInput {
  width: 50%;
  margin-left: 14px;
  background-color: transparent;
}

.DateInput_input {
  width: 100%;
  font-size: 14px;
  color: #fafafa;
  background: transparent;
  cursor: pointer;

}

.DateInput_input__focused {
  border: 0;
}

.DateRangePicker_picker {
  margin-top: 16px;
}

.DateRangePickerInput_arrow {
  height: 100%;
}

.CalendarDay {
  vertical-align: middle;
  border: none;
}

.CalendarDay:hover {
  border: none;
}

.CalendarDay__selected {
  color: white;
  border-radius: 50%;
  background-color: #57bcdd;
  position: relative;
}

.CalendarDay__selected:hover {
  border: none;
}

.CalendarDay__selected:hover,
.CalendarDay__selected_span:hover {
  background-color: #e9eef5;
}

.CalendarDay__selected_start,
.CalendarDay__selected_end {
  background-color: #e9eef5;
}

.CalendarDay__selected_start {
  border-radius: 50% 0 0 50%;
}

.CalendarDay__selected_end {
  border-radius: 0 50% 50% 0;
}

.CalendarDay__selected_end:first-of-type,
.CalendarDay__selected_end:last-of-type {
  background-color: transparent;
}


.CalendarDay__selected_start div,
.CalendarDay__selected_end div {
  background-color: #57bcdd;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CalendarDay__selected_span {
  background-color: #e9eef5;
  color: #1f1b1b;
}

.CalendarDay__selected_span:hover {
  color: #1f1b1b;
}

.CalendarDay__lastDayOfWeek.CalendarDay__selected_span {
  border-radius: 0 50% 50% 0;
}

.CalendarDay__firstDayOfWeek.CalendarDay__selected_span {
  border-radius: 50% 0 0 50%;
}
