.input {
  width: 251px;
  height: 50px;

  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 5px;
}

.row {
  display: flex;

  div:first-child {
    margin-right: 16px;
  }
}

.button {
  width: 132px;
  height: 50px;

  background: #57BCDD;
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.25);
  border-radius: 5px;
  border: none;

  font-weight: 800;
  font-size: 14px;
  line-height: 150%;
  color: #FAFAFA;
}

.left {
  width: 50%;
}

.password {
  font-weight: 600;
  font-size: 12px;
  color: #57BCDD;
  margin-bottom: 16px;
  margin-top: 16px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.close-icon {
  position: absolute;
  right: 40px;
  top: 40px;
  cursor: pointer;
}
