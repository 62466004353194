.wrapper {
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.title {
  font-weight: bold;
  font-size: 24px;
  color: #fafafa;
  margin-right: 10px;
}

.title-wrap {
  display: flex;
  align-items: center;
}

.map__container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.currency {
  color: #fafafa;
  font-size: 14px;
}

.icon-map {
  margin-right: 10px;
}

.sort__button {
  background: transparent;
  color: #fafafa;
  border: none;
  outline: none;
  font-size: 14px;
  cursor: pointer;
  &__label {
    margin-right: 8px;
  }
}
