.close-icon {
  position: absolute;
  right: 40px;
  top: 40px;
  cursor: pointer;
}

.title {
  margin-bottom: 12px;
}
