.contacts {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;
  //margin-top: 246px;
  margin: 246px auto;
  color: #FAFAFA;
}


h1 {
  font-size: 24px;
  color: #FAFAFA;
  text-align: center;
}

ul {
  list-style-type: decimal;
  margin-left: 20px;
}

.contacts p {
  text-indent: 2em;
}

@media screen and (max-width: 600px) {
  .contacts {
    width: 90%;
  }
}