.header {
  position: relative;
  display: flex;
  height: 100vh;
  flex-direction: column;
  background-image: url('./img/bg_main.jpg');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.DateRangePicker_picker {
  z-index: 100;
}
