.input {
  padding-left: 10px;

  &--error {
    border-color: red;
  }
}

.error {
  color: red;
  display: inline-block;
  margin-left: 10px;
}
