.wrapper {
  position: relative;
  width: auto;
  height: auto;
}

.dropdown {
  position: absolute;
  left: 0;
  right: 0;
  top: 60px;
  min-width: 197px;
  background: #fafafa;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  display: block;
  z-index: 200;
  &--focused {
    border: 1px solid red;
    box-sizing: border-box;
  }
}

.size-l {
  height: 50px;
}

.right {
  right: -82%;
  left: auto;
}
