.filters {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 0 -16px;
  width: calc(100% + 16px);
  justify-content: flex-start;
  align-items: center;
  > * {
    margin: 0 0 0 16px;
  }

  input {
    margin-bottom: 0;
  }
}
.input {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #938884;
  box-sizing: border-box;
  padding: 17px 20px;
  border-radius: 5px;
  cursor: pointer;
}
.input-wrapper {
  font-size: 14px;
  color: #fafafa;
  width: 20px;
  text-align: center;
  margin-left: 16px;
}
.menu {
  display: flex;
  flex-flow: row;
  align-items: center;
  padding: 16px 0;
}
.rooms-wrapper {
  font-size: 14px;
  color: #fafafa;
}
.dropdown {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.dropdown-wrapper {
  display: flex;
  flex-flow: row;
  padding: 16px 20px 0 20px;
  align-items: flex-start;
}
