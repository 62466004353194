.wrapper {
  background-color: #fafafa;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
  width: 100%;
  border-radius: 5px;
  padding: 28px;
  height: 434px;
  box-sizing: border-box;
  display: grid;
  gap: 22px 40px;
  grid-template-columns: [one] 37%;
  grid-template-rows: repeat(2, calc(50% - 10px));
  grid-template-areas:
    'one two three'
    'one four four';
}

.image {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 5px;
}

.info {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: flex-end;
}

.description {
  @extend .info;
  margin-top: 10px;
}

.cell {
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  > img {
    border-radius: 5px;
    height: calc(100% - 45px);
    width: 100%;
  }
}

.one {
  grid-area: one;
}

.two {
  grid-area: two;
}

.three {
  grid-area: three;
}

.four {
  grid-area: four;
}

.bold {
  font-weight: bold;
}

.lg {
  font-size: 20px;
}

.m {
  font-size: 12px;
}

