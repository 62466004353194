.button {
  height: 50px;
  border-radius: 5px;
  background-color: #57bcdd;
  color: #fff;
  outline: none;
  border: none;
  box-sizing: border-box;
  padding: 14px 40px;
  margin-top: 20px !important;
  font-weight: 800;
  font-size: 14px;
  cursor: pointer;
}

.button--shadowed {
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.25);
}
