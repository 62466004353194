.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #E9EEF5;
    opacity: .5;
  }
}

.loaderItem {
  z-index: 20;
  border: 10px solid #57BCDD;
  border-radius: 50%;
}
