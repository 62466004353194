.header__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 30px 50px 0 50px;
  position: relative;
}

.header__menu {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  font-size: 12px;
  color: #ffffff;
  &--centered {
    margin-left: 50px;
  }
}

.header__item {
  flex-direction: row;
  text-transform: uppercase;
  margin-right: 46px;
  a {
    text-decoration: none;
    color: #fff;
  }
}

.header__user {
  width: 40px;
  height: 40px;
  background-color: rgba(250, 250, 250, 0.1);
  border-radius: 50%;
  cursor: pointer;
}

.header__user__menu {
  display: block;
  position: absolute;
  list-style-type: none;
  width: 104px;
  background: #FAFAFA;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border: none;
  right: 55px;
  top: 80px;

  &.hidden {
    display: none;
  }

  li {
    cursor: pointer;
    border-bottom: 1px solid #E9EEF5;
    border-radius: 5px;
    font-size: 12px;

    &:last-child {
      padding: 9px 15px;
    }

    a {
      color: #000;
      text-decoration: none;
      padding: 9px 15px;
      display: block;
    }
  }

  li:last-child {
    border: none;
  }

  li:hover {
    background: #E9EEF5;
  }
}

.header__logo {
  width: 108px;
  height: 30px;
}
