.terms {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  margin-right: 6px;
  margin-bottom: 10px;

  a {
    cursor: pointer;
    color: #57BCDD;

    &:hover {
      text-decoration: underline;
    }
  }
}
