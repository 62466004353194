.divider {
  text-align: center;
  position: relative;

  &:before, &:after {
    content: '';
    position: absolute;
    height: 1px;
    width: 46%;
    top: 50%;
    opacity: 0.2;
    border: 1px solid #1F1B1B;
    box-sizing: border-box;
    border-radius: 5px;
  }

  &:before {
    left: 0;
  }

  &:after {
    right: 0;
  }
}

span {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  margin-right: 6px;
}

.social {
  margin-top: 30px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  button {
    width: 140px;
    height: 50px;
    background: #E9EEF5;
    border-radius: 3px;
    border: none;
    display: flex;
    align-items: center;

    div {
      height: 42px;
      width: 42px;
      background: #FFF;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      width: 24px;
      height: 24px;
    }

    span {
      margin-left: 20px;
    }
  }
}

.link {
  cursor: pointer;
  color: #57BCDD;

  &:hover {
    text-decoration: underline;
  }
}
