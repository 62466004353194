thead tr th {
  position: sticky;
  top: 0;
  z-index: 2;
}

th {
  padding: 16px 16px 16px 15px;
  background: #FAFAFA;
  text-align: left;
}
