.wrapper {
  display: flex;
  flex-flow: row;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.button {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.decrease {
  border: 1px solid #57bcdd;
  background-color: #fff;
  color: #57bcdd;
}

.increase {
  border: 1px solid #57bcdd;
  background-color: #57bcdd;
  color: #fff;
}

.value {
  color: #1f1b1b;
  display: flex;
  justify-content: center;
  width: 28px;
}
