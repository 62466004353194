.details {
  color: #57BCDD;
  cursor: pointer;
}

.cancel {
  visibility: hidden;
  color: #F58686;
  cursor: pointer;
}

.description {
  overflow: hidden;
  height: 20px;
  width: 210px;
}

.description--expanded {
  height: auto;
}

.arrow {
  border: solid #57BCDD;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  margin: 0 0 3px 10px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.arrow--up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  margin: 0 0 0 10px;
}

.reservationsTable {
  width: 100%;
  padding: 28px 28px 0 28px;

  thead {
    font-size: 24px;
    * {
      margin-bottom: 20px;
    }
  }

  th {
    text-align: left;
    font-size: 12px;
    color: #9E9E9E;
  }

  tr:hover {
    .cancel {
      visibility: visible;
    }
  }

  td {
    padding: 20px 20px 20px 0;
    font-size: 14px;
    color: #1F1B1B;
  }
}

.button {
  width: 132px;
  height: 50px;

  background: #57BCDD;
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.25);
  border-radius: 5px;
  border: none;

  font-weight: 800;
  font-size: 14px;
  line-height: 150%;
  color: #FAFAFA;
}

.reservations {
  padding: 28px 28px 0 28px;
}

.reservation {
  &__row {
    width: 100%;
    display: inline-flex;
    flex-flow: row;
    align-items: flex-start;
    justify-content: flex-start;
    border-bottom: 1px solid #E9EEF5;
    border-radius: 1px;
    padding: 20px 0;
    &:last-of-type {
      border: none;
      padding-bottom: 0;
    }
    &:first-of-type {
      padding-top: 0;
    }
  }
  &__column {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-right: 30px;
    &:nth-of-type(1) {
      flex: 1 1 10%;
    }
    &:nth-of-type(2) {
      flex: 1 1 10%;
    }
    &:nth-of-type(3) {
      flex: 1 1 20%;
    }
    &:nth-of-type(4) {
      flex: 1 1 30%;
    }
    &:nth-of-type(5) {
      flex: 1 1 30%;
    }
    &:last-of-type {
      margin-right: 0;
    }
    &__row {
      margin-bottom: 20px;
      line-height: 28px;
      box-sizing: border-box;
      &:last-of-type {
        margin: 0;
      }
    }
  }
  &__button {
    padding: 0 28px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    border-radius: 5px;
    &--cancelled {
      background-color: #F58686;
    }
    &--confirmed {
      background-color: #5EBB94;
    }
  }
  &__bold {
    font-size: 14px;
    font-weight: 600;
  }
  &__secondary {
    font-size: 12px;
    font-weight: 600;
    color: #9E9E9E;
  }
  &__cancel {
    border: none;
    color: #F58686;
    outline: none;
    background-color: transparent;
    padding: 0;
  }
}
